import React from "react";

const About = () => {
  return (
    <div>
      <p className="text-center text-yellow mb-4">Who we are</p>
      <h2 className="text-center mb-8">
        Jordanspicks Puts Integrity First in Sports Betting
      </h2>
      <p className="my-4 text-center text-lightgrey2">
        Sports betting, once confined to the shadows of Las Vegas, has evolved
        into a complex game with a tumultuous history. In the early days, when
        Las Vegas held a monopoly on legal betting in the United States, sports
        bettors faced a murky landscape riddled with swindlers peddling lofty
        promises and outright lies. Even as sports betting gains mainstream
        acceptance, potential pitfalls continue to lurk in the shadows.
      </p>
      <p className="my-4 text-center text-lightgrey2">
        At Jordanspicks, we distinguish ourselves by taking a refreshing and
        honest approach to sports betting. Unlike others in the industry, we
        refrain from aggressive upselling, imposing numerous packages, or
        treating our clients as mere commodities. Our mission is to provide
        sports bettors with a clear, straightforward, and transparent
        experience.
      </p>
      <p className="my-4 text-center text-lightgrey2">
        The roots of sports betting trace back to an era when Las Vegas stood as
        the sole haven for legal wagering in the U.S. However, this environment
        proved treacherous for unsuspecting sports enthusiasts who fell victim
        to unscrupulous operators and fraudulent schemes. As the landscape of
        sports betting undergoes a transformation into a more widely accepted
        form of entertainment, the dangers of misinformation and deceit persist.
      </p>
      <p className="my-4 text-center text-lightgrey2">
        Enter Jordanspicks, where we break away from the shadows of the past. We
        understand the skepticism bred by a history of broken promises and
        unethical practices. That's why we prioritize integrity, offering a
        different narrative in the world of sports betting. Our commitment is
        evident in our refusal to employ aggressive sales tactics, bombard you
        with unnecessary packages, or diminish your value as a bettor.
      </p>
      <p className="my-4 text-center text-lightgrey2">
        Our vision is simple: to redefine the sports betting experience by
        fostering a community built on trust, transparency, and a genuine
        passion for the game. At Jordanspicks, you're not just a bettor; you're
        an integral part of an authentic and straightforward journey into the
        thrilling world of sports wagering.
      </p>
    </div>
  );
};

export default About;
