import React from "react";

const Terms = () => {
  return (
    <div>
      <div>
        <h2>Terms and Conditions</h2>
        <section className="my-8">
          <h4 className="mb-2">User Responsibilities</h4>
          <p>
            If you or someone you know has a gambling problem, crisis counseling
            and referral services can be accessed by calling 1-800-GAMBLER
            (1-800-426-2537).
          </p>
          <p className="mt-2">
            Before using this site and any featured services, please ensure you
            understand and comply with your local laws. Jordans Picks doesn’t
            guide on the legality of online betting or gambling in your area.
            You’re responsible for adhering to laws applicable to you. Jordans
            Picks isn’t liable for your use of this site or its information. By
            using this site, you agree to not hold the site owner accountable
            for any claims related to services on featured third-party sites.
          </p>
          <p className="mt-2">
            Please note that this site is intended for individuals who are 21
            years of age or older. If you are not yet 21, please refrain from
            using this site.
          </p>
        </section>
        <section className="my-8">
          <h4 className="mb-2">Mobile Phone Number Authorization</h4>
          <p>
            By submitting your mobile phone number during registration, you're
            authorising us (opting in) to send you informational and marketing
            related texts. Message/data rates apply. Reply STOP to unsubscribe.
          </p>
        </section>
      </div>
      <div>
        <h2>Privacy Policy</h2>
        <section className="my-8">
          <h4 className="mb-2">Comments</h4>
          <p>
            When visitors leave comments on the site we collect the data shown
            in the comments form, and also the visitor’s IP address and browser
            user agent string to help spam detection.
          </p>
          <p className="mt-2">
            An anonymized string created from your email address (also called a
            hash) may be provided to the Gravatar service to see if you are
            using it. The Gravatar service privacy policy is available here.
            After approval of your comment, your profile picture is visible to
            the public in the context of your comment.
          </p>
        </section>
        <section className="my-8">
          <h4 className="mb-2">Media</h4>
          <p>
            If you upload images to the website, you should avoid uploading
            images with embedded location data (EXIF GPS) included. Visitors to
            the website can download and extract any location data from images
            on the website.
          </p>
        </section>
        <section className="my-8">
          <h4 className="mb-2">Cookies</h4>
          <p>
            If you leave a comment on our site you may opt-in to saving your
            name, email address and website in cookies. These are for your
            convenience so that you do not have to fill in your details again
            when you leave another comment. These cookies will last for one
            year.
          </p>
          <p className="mt-2">
            If you visit our login page, we will set a temporary cookie to
            determine if your browser accepts cookies. This cookie contains no
            personal data and is discarded when you close your browser.
          </p>
          <p className="mt-2">
            When you log in, we will also set up several cookies to save your
            login information and your screen display choices. Login cookies
            last for two days, and screen options cookies last for a year. If
            you select "Remember Me", your login will persist for two weeks. If
            you log out of your account, the login cookies will be removed.
          </p>
          <p className="mt-2">
            If you edit or publish an article, an additional cookie will be
            saved in your browser. This cookie includes no personal data and
            simply indicates the post ID of the article you just edited. It
            expires after 1 day.
          </p>
        </section>
        <section className="my-8">
          <h4 className="mb-2">Embedded content from other websites</h4>
          <p>
            Articles on this site may include embedded content (e.g. videos,
            images, articles, etc.). Embedded content from other websites
            behaves in the exact same way as if the visitor has visited the
            other website.
          </p>
          <p className="mt-2">
            These websites may collect data about you, use cookies, embed
            additional third-party tracking, and monitor your interaction with
            that embedded content, including tracking your interaction with the
            embedded content if you have an account and are logged in to that
            website.
          </p>
        </section>
        <section className="my-8">
          <h4 className="mb-2">Who we share your data with</h4>
          <p>
            If you request a password reset, your IP address will be included in
            the reset email.
          </p>
        </section>
        <section className="my-8">
          <h4 className="mb-2">How long we retain your data</h4>
          <p>
            If you leave a comment, the comment and its metadata are retained
            indefinitely. This is so we can recognize and approve any follow-up
            comments automatically instead of holding them in a moderation
            queue.
          </p>
          <p className="mt-2">
            For users that register on our website (if any), we also store the
            personal information they provide in their user profile. All users
            can see, edit, or delete their personal information at any time
            (except they cannot change their username). Website administrators
            can also see and edit that information.
          </p>
        </section>
        <section className="my-8">
          <h4 className="mb-2">What rights you have over your data</h4>
          <p>
            If you have an account on this site, or have left comments, you can
            request to receive an exported file of the personal data we hold
            about you, including any data you have provided to us. You can also
            request that we erase any personal data we hold about you. This does
            not include any data we are obliged to keep for administrative,
            legal, or security purposes.
          </p>
        </section>
        <section className="my-8">
          <h4 className="mb-2">Where your data is sent</h4>
          <p>
            Visitor comments may be checked through an automated spam detection
            service.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Terms;
